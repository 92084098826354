<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="9">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Project Name"
                label-for="project-name"
                label-cols-md="3"
              >
                <b-form-input
                  id="project-name"
                  :value="detail.projectName"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Short Description"
                label-for="short-description"
                label-cols-md="3"
              >
                <b-form-textarea
                  id="short-description"
                  rows="3"
                  :value="detail.projectDescription"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Customer"
                label-for="customer"
                label-cols-md="3"
              >
                <v-select
                  v-model="detail.selectedCustomerName"
                  label="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Partner"
                label-for="partner"
                label-cols-md="3"
              >
                <v-select
                  v-if="detail.selectedPartnerName.value != 'No Partner'"
                  v-model="detail.selectedPartnerName"
                  label="text"
                  disabled
                />
                <b-form-input
                  v-else
                  id="partner"
                  value="No Partner"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Business Type"
                label-for="business-type"
                label-cols-md="3"
              >
                <v-select
                  v-model="detail.selectedBusinessTypeName"
                  label="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Project Start"
                label-for="project-start"
                label-cols-md="3"
              >
                <b-form-input
                  id="project-start"
                  type="month"
                  :value="detail.projectStart"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Hashtags"
                label-for="hashtags"
                label-cols-md="3"
              >
                <v-select
                  v-model="detail.selectedHashtagsName"
                  multiple
                  label="text"
                  disabled
                  class="changeBackgroundColor"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Development Tool"
                label-for="development-tool"
                label-cols-md="3"
              >
                <v-select
                  v-model="detail.selectedDevelopmentToolName"
                  multiple
                  label="text"
                  disabled
                  class="changeBackgroundColor"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Programming Language"
                label-for="programming-language"
                label-cols-md="3"
              >
                <v-select
                  v-model="detail.selectedProgrammingLanguageName"
                  multiple
                  label="text"
                  disabled
                  class="changeBackgroundColor"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Database"
                label-for="database"
                label-cols-md="3"
              >
                <v-select
                  v-model="detail.selectedProjectDatabaseName"
                  multiple
                  label="text"
                  disabled
                  class="changeBackgroundColor"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Team Owner"
                label-for="team-owner"
                label-cols-md="3"
              >
                <v-select
                  v-model="detail.selectedTeamOwnerName"
                  multiple
                  label="text"
                  disabled
                  class="changeBackgroundColor"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Cover Picture"
                label-for="cover-picture"
                label-cols-md="3"
              >
                <b-form-input
                  id="cover-picture"
                  v-model="detail.coverPictureName"
                  :placeholder="detail.coverPictureName == null ? 'No file chosen' : ''"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Power Point"
                label-for="power-point"
                label-cols-md="3"
              >
                <b-input-group>
                  <b-form-input
                    id="power-point"
                    :value="detail.powerPointFile"
                    readonly
                  />
                  <b-input-group-append>
                    <b-button
                      variant="success"
                      @click="download(detail.projectId)"
                    >
                      Download
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12 mb-2">
              <b-form-checkbox
                v-model="detail.publish"
                :checked="detail.publish"
                name="publish"
                switch
                inline
                class="offset-md-3"
                disabled
              >
                Publish
              </b-form-checkbox>
            </b-col>
            <b-col cols="12">
              <b-button
                class="offset-md-3"
                variant="warning"
                @click="rediractToManageDetail(detail.projectId)"
              >
                Edit
              </b-button>
              <b-button
                class="float-right"
                variant="outline-danger"
                @click="showModal(detail.projectId, detail.projectName)"
              >
                Delete
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col cols="12">
              <b-img
                v-if="detail.coverPicture == null"
                :src="require(`@/assets/images/no-image.jpg`)"
                fluid
                alt="Cover picture"
              />
              <b-img
                v-else
                :src="$webApi + '/' + detail.coverPicture"
                fluid
                alt="Cover picture"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-modal
        ref="modal-errorDeleteProject"
        ok-only
        ok-variant="warning"
        ok-title="Close"
        modal-class="modal-warning"
        centered
        title="Delete failed"
      >
        <b-card-text>
          {{ errorMessageDeleteProject }}
        </b-card-text>
      </b-modal>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BImg, BModal, VBModal, BCardText, BFormCheckbox, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BImg,
    BModal,
    BCardText,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      dir: 'ltr',
      detail: [],
      errorMessageDeleteProject: '',
    }
  },
  created() {
    this.showDetail(this.$route.params.projectId)
  },
  methods: {
    showDetail(id) {
      useJwt.getProjectDetail(id).then(response => {
        this.detail = response.data.result
      }).catch()
    },
    showModal(projectId, projectName) {
      this.messageModal = ''
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete project: ${projectName}`, {
          title: 'Please Confirm',
          size: 'md',
          okVariant: 'danger',
          okTitle: 'Confirm',
          cancelTitle: 'Close',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeProject(projectId)
          }
        })
    },
    removeProject(id) {
      useJwt.deleteProject(id, this.detail.coverPicture, this.detail.powerPointFile)
        .then(
          this.showToastDeletedSuccess('success'),
        )
        .catch(error => {
          this.errorMessageDeleteProject = error
          this.$refs['modal-errorDeleteProject'].show()
        })
    },
    showToastDeletedSuccess(variant = null) {
      this.$bvToast.toast('Deleted successfully', {
        title: 'Alert Message',
        variant,
        solid: true,
        autoHideDelay: 2000,
      })
      setTimeout(() => this.$router.push({ name: 'projects' }), 2500)
    },
    rediractToManageDetail(id) {
      this.$router.push({ name: 'editProject', params: { projectId: id } })
    },
    download(id) {
      this.loading = true

      useJwt.getProjectPowerPoint(id)
        .then(response => {
          this.loading = false
          const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
          const fileLink = document.createElement('a')
          const contentDisposition = response.headers['content-disposition']

          const match = contentDisposition.match(/filename\s*=\s*"?(.+)"?;/)
          const filename = match[1]
          fileLink.href = fileURL
          fileLink.setAttribute('download', filename)
          document.body.appendChild(fileLink)

          fileLink.click()
        }).then(() => {
          this.loading = false
        }).catch(error => {
          this.loading = false
          if (typeof error.response.data.error !== 'undefined') {
            this.$swal({
              icon: 'error',
              title: 'File not found',
              html: `Powerpoint of this project does not exists  : ${error.response.data.error.join(',<br>')}`,
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.changeBackgroundColor > .vs__dropdown-toggle > .vs__selected-options > .vs__selected {
  background-color: #7367f0 !important;
}
</style>
